import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import 'suneditor/dist/css/suneditor.min.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import profile from '../componentes/Imagens/profile.jpg'
import { useUtilitarios } from '../hooks/useUtilitarios'


export default function DetalheFiliacao() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idFederacao = queryParams.get('f');
    const filiacaoExt = queryParams.get('fl');


    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
        federacoes: firebase.firestore().collection('federacoes')

    })
    const [equipe, setEquipe] = useState("")
    const [usuario, setUsuario] = useState({})
    const [usuarioFiliacao, setUsuarioFiliacao] = useState({})
    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null)
    console.log('usuarioSelecionado: ', usuarioSelecionado);
    

    const [filiacao, setFiliacao] = useState({})

    const [federacao, setFederacao] = useState({})


    const [pagamentoPendente, setPagamentoPendente] = useState({})
    const [ultimoPagamento, setUltimoPagamento] = useState({})
    const [pagamentosAprovados, setPagamentosAprovados] = useState([])

    const [carteirinhas, setCarteirinhas] = useState([])
    const [ultimaCarteirinhaDigital, setTltimaCarteirinhaDigital] = useState({})


    const [idade, setIdade] = useState({})
    const [competidores, setCompetidores] = useState([])

    const utilitarios = useUtilitarios()

    useEffect(() => {

        servicos.auth.onAuthStateChanged(async (user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))

            if (user || idUser) {

                servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then(async (doc) => {
                    if (doc.exists) {
                        const _usuario = doc.data()
                        setUsuario(_usuario)

                        // carregar Federação
                        servicos.federacoes.doc(idFederacao).get().then(snap => {
                            setFederacao(snap.data());
                        })

                        if (_usuario.usuario === 2) {
                            setUsuarioFiliacao(_usuario)

                            await utilitarios.atualizarStatusFiliacao(idFederacao, [_usuario])

                            // Carregar equipe e professor
                            if (_usuario.equipe) {

                                servicos.equipes.doc(_usuario.equipe).get().then((e) => {
                                    setEquipe(e.data().equipe ? e.data().equipe : '')
                                })

                            }


                            // carregar filiação

                            if (_usuario?.filiacoes?.length > 0) {
                                if (_usuario?.filiacoes.includes(idFederacao)) {
                                    const docRef = await servicos.filiacoes.where('idFiliado', '==', _usuario.id).where('tipoUsuario', '==', _usuario.tipoUsuario).get();
                                    const data = docRef.docs.map(d => d.data())[0];
                                    setFiliacao(data);

                                    // Carregar Pagamento
                                    if (data.id) {
                                        servicos.filiacoes.doc(data.id).collection('pagamentos').get().then(snap => {
                                            const dataPgtos = snap.docs.map(d => d.data())

                                            if (dataPgtos.length > 0) {
                                                const _ultimoPagamento = dataPgtos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                                setUltimoPagamento(_ultimoPagamento)
                                            }

                                        })

                                    }
                                }
                            }

                        }

                        if (_usuario.usuario === 3) {

                            servicos.usuarios.doc(_usuario.id).collection('competidores').get().then(async snap => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(d => d.data())

                                    const _competidores = data.map(comp => {
                                        return { ...comp, responsavel: _usuario.id }
                                    })
                                    setCompetidores(_competidores)

                                    const usuarios = [_usuario, ..._competidores]

                                    await utilitarios.atualizarStatusFiliacao(idFederacao, usuarios)

                                }
                            })
                        }

                    }

                })

            } else {
                window.location = '/'
            }

        })


    }, [])

    useEffect(async () => {
        if (filiacaoExt) {
            const docRefFiliacao = await servicos.filiacoes.doc(filiacaoExt).get();
            const dataFiliacao = docRefFiliacao.data();
            setFiliacao(dataFiliacao);

            if (dataFiliacao.idResponsavel) {
                // Carregar Dados do Usuário
                const docRefAluno = await servicos.usuarios.doc(dataFiliacao.idResponsavel).collection('competidores').doc(dataFiliacao.idFiliado).get()


                if (docRefAluno.exists) {
                    const dataAluno = docRefAluno.data()
                    setUsuarioFiliacao(dataAluno)
                    // const index = competidores.findIndex(comp => comp.id === dataAluno.id)
                    // setUsuarioSelecionado(index)

                    // Carregar equipe 
                    if (dataAluno.equipe) {

                        servicos.equipes.doc(dataAluno.equipe).get().then((e) => {
                            setEquipe(e.data().equipe ? e.data().equipe : '')
                        })

                    }
                }


            } else {
                setUsuarioFiliacao(usuario)
                // setUsuarioSelecionado('eu')
                if (usuario.equipe) {

                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data().equipe ? e.data().equipe : '')
                    })

                }
                // Carregar equipe 
                if (usuario.equipe) {

                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data().equipe ? e.data().equipe : '')
                    })

                }
            }

            // Carregar Último Pagamento
            servicos.filiacoes.doc(filiacaoExt).collection('pagamentos').get().then(snap => {
                const dataPgtos = snap.docs.map(d => d.data())

                if (dataPgtos.length > 0) {
                    const _ultimoPagamento = dataPgtos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                    setUltimoPagamento(_ultimoPagamento)
                }

            })
        }
    }, [filiacaoExt, usuario])

    function mensagemStatus(status) {
        let mensagem = ''
        switch (status) {
            case 1: mensagem = 'Aguardando aprovação da documentação';
                break;
            case 2: mensagem = 'Aprovada';
                break;
            case 3: mensagem = 'Documentação Reprovada';
                break;
            case 4: mensagem = 'Vencida (pendente de renovação)';
                break;
            default: mensagem = '';
                break
        }

        return mensagem;

    }
    function mensagemCor(status) {
        let cor = ''

        switch (status) {
            case 1: case 'Pendente': cor = 'orange'; break;
            case 2: case 'Aprovada': cor = 'green'; break;
            case 3: case 'Reprovada': cor = 'red'; break;
            case 4: case 'Vencida': cor = 'purple'; break;
            default: cor = ''; break;
        }

        return cor;

    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function converterData(data) {

        const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD')

        const [ano, mes, dia] = _data.split('-')

        return moment([parseInt(ano) + 1, mes, dia].join('-')).format('DD/MM/YYYY')

    }


    function dataVencimento(data) {

        const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD')

        const [ano, mes, dia] = _data.split('-')
        const vencimento = federacao?.regrasFiliacao?.vencimentoAnuidade;
        const umAnoAposAprovacao = '1';
        const aoFimDoAno = '2';


        if (vencimento == umAnoAposAprovacao) {
            return moment([parseInt(ano) + 1, mes, dia].join('-')).format('DD/MM/YYYY');
        } else if (vencimento == aoFimDoAno) {
            return `31/12/${ano}`;
        }


    }




    const handleCompetidor = async (event) => {
        event.persist();
        setUsuarioSelecionado(event.target.value)
        setUsuarioFiliacao({})
        setFiliacao({})
        setEquipe('')
        setUltimoPagamento({})
        // setCompetidorSelecionado(event.target.value);
        if (event.target.value !== "") {
            if (event.target.value === "eu") {

                setUsuarioFiliacao(usuario);

                // Carrega Equipe
                if (usuario?.equipe) {
                    servicos.equipes.doc(usuario.equipe).get().then((e) => {
                        setEquipe(e.data()?.equipe ? e.data().equipe : '');
                    })

                }

                // Carregar os dados da filiação do usuário selecionado

                if (usuario?.filiacoes?.length > 0 && usuario?.filiacoes.includes(idFederacao)) {

                    const snap = await servicos.filiacoes.where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

                    if (!snap.empty) {
                        const data = snap.docs.map(d => d.data())[0]
                        setFiliacao(data);

                        // Carregar Último Pagamento
                        servicos.filiacoes.doc(data.id).collection('pagamentos').get().then(snap => {
                            const dataPgtos = snap.docs.map(d => d.data())

                            if (dataPgtos.length > 0) {
                                const _ultimoPagamento = dataPgtos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                setUltimoPagamento(_ultimoPagamento)
                            }

                        })
                    } else {
                        setFiliacao({ semFiliacao: true })
                    }


                } else {
                    setFiliacao({ semFiliacao: true })
                }

            } else {

                const competidor = competidores[event.target.value]

                setUsuarioFiliacao(competidor)

                // Carregar Equipe
                if (competidor.equipe) {
                    servicos.equipes.doc(competidor.equipe).get().then((e) => {
                        setEquipe(e.data()?.equipe ? e.data()?.equipe : '')
                    })

                }

                if (competidor?.filiacoes?.length > 0) {
                    if (competidor?.filiacoes.includes(idFederacao)) {

                        const snap = await servicos.filiacoes.where('idFiliado', '==', competidor.id).where('tipoUsuario', '==', competidor.tipoUsuario).get();

                        if (!snap.empty) {
                            const data = snap.docs.map(d => d.data())[0]
                            setFiliacao(data);

                            // Carregar Último Pagamento
                            servicos.filiacoes.doc(data.id).collection('pagamentos').get().then(snap => {
                                const dataPgtos = snap.docs.map(d => d.data())

                                if (dataPgtos.length > 0) {
                                    const _ultimoPagamento = dataPgtos.filter(pgto => pgto.status == 2).sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
                                    setUltimoPagamento(_ultimoPagamento)
                                }

                            })
                        } else {
                            setFiliacao({ semFiliacao: true })
                        }

                    } else {
                        setFiliacao({ semFiliacao: true })
                    }
                } else {
                    setFiliacao({ semFiliacao: true })
                }

            }
        }
    }

    async function excluirFiliacao() {

        const text = filiacao.statusFiliacao === 2 ? 'Atenção: A sua inscrição está aprovada e <span  style="color:green; font-weight:bold;">Ativa</span>. Se cancelar não poderá mais usufruir dos benefíficios e vantagems da Filiação. Confirma o cancelamento mesmo assim?' :
            filiacao.statusFiliacao === 1 ?
                'Atenção: A sua inscrição está com situação <span  style="color:orange; font-weight:bold;">Pendente</span>, o que significa que a documentação anexada ainda não foi aprovada. Deseja realizar o cancelamento mesmo assim?' :
                'A filiação será desvinculada do seu cadastro. Caso queira filiar-se novamente, basta preencher com os documentos necessários para a filiação.';



        const result = await Swal.fire({
            title: 'Confirma o cancelamento da Filiação?',
            html: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Não',
            customClass: {
                content: 'swal-message'
            }
        })

        if (!result.value) {
            return;
        }

        mensagemLoading('cancelando filiação...')

        try {
            // excluir Filiação
            // Remover a referencia da federação da collection usuarios

            await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).delete();

            if (['competidor', 'responsavel', 'professor'].includes(usuarioFiliacao.tipoUsuario)) {
                await servicos.usuarios.doc(usuarioFiliacao.id).update({
                    filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
                })
            } else if (usuarioFiliacao.tipoUsuario === 'aluno') {
                servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
                    filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
                })
            }

            // remover os arquivos anexados

            let caminho = `federacoes/${idFederacao}/filiacoes/${filiacao.id}`

            try {
                await firebase.storage().ref(`${caminho}/rg`).delete();
            } catch (error) {
                console.log('Erro ao excluir rg: ', error);
            }

            try {
                await firebase.storage().ref(`${caminho}/foto`).delete();

            } catch (error) {
                console.log('Erro ao excluir foto: ', error);
            }

            if (pagamentosAprovados?.length > 0) {
                for (let pagamento of pagamentosAprovados) {

                    try {
                        await firebase.storage().ref(`${caminho}/pagamentos/${pagamento.id}`).delete();

                    } catch (error) {
                        console.log('Erro ao excluir pagamento: ', error.message);

                    }

                }
            }

            if (pagamentoPendente.id) {

                try {
                    await firebase.storage().ref(`${caminho}/pagamentos/${pagamentoPendente.id}`).delete();

                } catch (error) {
                    console.log('Erro ao excluir pagamento: ', error.message);

                }

            }

            Swal.fire('Sucesso', 'Filiação cancelada. Você pode filiar-se novamente quando quiser.', 'success').then(() => {
                window.location = '/federacoes'
            })

        } catch (error) {
            mensagemErro('Não foi possível concluír a exclusão. Entre em contato com o suporte.')
            console.log('erro: ', error.message);
        }



    }


    function exibirBotaoCarteirinhaDigital() {
        return (
            // obs: statusFiliacao já vai estar setado com 2 (Ativo)
            federacao?.carteirinha?.carteirinhaDigital == '1' ||
            (federacao?.carteirinha?.carteirinhaDigital == '2' && filiacao?.habilitarCarteirinhaDigital == '1')


        )
    }

    function linkFiliarSe(){

        let url = '';

        if(usuarioSelecionado === 'eu'){
            url = `/filiacao/${idFederacao}?comp=eu`
        }else{
            url = `/filiacao/${idFederacao}?comp=${usuarioSelecionado}`
        }

        return url;
    }


    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>{'Dados da Filiação'}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Confira a baixo os dados de sua Filiação</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">

                            {usuario.usuario === 3 && (
                                <p class="contact-form-name vc_col-sm-4">
                                    <span class="wpcf7-form-control-wrap your-name"
                                    >
                                        <h6>Selecione o Filiado <i class="fa fa-hand-o-down" aria-hidden="true"></i></h6>
                                        <select name="select" id="select"
                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%' }} onChange={handleCompetidor} value={usuarioSelecionado}>
                                            <option value="">Selecione o filiado </option>
                                            <option value="eu">{usuario.nome} - (Responsável) </option>
                                            {competidores.sort((a, b) => a.nome - b.nome).map((c, index) =>
                                                <option value={index} >{c.nome} {c.sobrenome} - {c.cpf}</option>
                                            )}
                                        </select>
                                    </span>
                                </p>
                            )}

                            {filiacao.semFiliacao && (

                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <h3 style={{ marginLeft: '43px' }}>Usuário Sem Filiação</h3>
                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                        <a href={linkFiliarSe()} class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> Filiar-se</a>
                                    </div>
                                </div>
                            )}

                            {usuarioFiliacao.id && filiacao.id && (
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                                <div class="package-style-1 pnchr-package clearfix vc_col-sm-3">
                                                    <div style={{ height: '300px', background: `url('${filiacao?.urlImagemFoto ? filiacao.urlImagemFoto : profile}') no-repeat center/contain`, padding: '0px' }}>
                                                        {/* <img src={filiacao.urlImagemFoto ? filiacao.urlImagemFoto : profile} alt="" title="image-here-1" style={{objectFit:'cover', objectPosition:'center', height:'100%', width:'100%'}} /> */}
                                                    </div>
                                                </div>
                                                <div role="form" class="wpcf7 row vc_col-sm-8" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                    <div class="screen-reader-response"></div>
                                                    <p class="contact-form-name vc_col-sm-12">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h2>Filiação Nº {filiacao.numeroFiliacao ? filiacao.numeroFiliacao.toString().padStart(4, '0') : ''}</h2><br />
                                                            <div className="ratings-widget__details-title" ><h4 style={{ color: "#585858" }}>Filiado: {filiacao.nomeFiliado
                                                            }</h4></div>
                                                            <div className="ratings-widget__details-title" ><h4 style={{ color: "#585858" }}>CPF: {filiacao.cpfFiliado}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Data de Nascimento: {usuarioFiliacao.dataNascimento ?
                                                                moment(usuarioFiliacao.dataNascimento).format('DD/MM/YYYY') : 'não informada'
                                                            }</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Sexo: {usuarioFiliacao.sexo}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Equipe: {equipe}</h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Faixa: {filiacao.graduacao}</h4></div><br />
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Vencimento: <span>{filiacao?.dtAprovado ?
                                                                dataVencimento(filiacao?.dtAprovado) : '--'}</span></h4></div>
                                                            <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Situação: <span style={{ color: mensagemCor(filiacao?.statusFiliacao) }}>{filiacao?.statusFiliacao ? mensagemStatus(filiacao.statusFiliacao) : ''}</span></h4></div>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {usuarioFiliacao.id && filiacao.id && (
                                <>
                                    <div class="wpb_column vc_column_container vc_col-sm-8" style={{ marginTop: 20 }}>
                                        <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                            <a href={`/filiacao/${idFederacao}?fl=${filiacao.id}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#17a2b8' }}><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i>  {filiacao.statusFiliacao === 4 ? 'Renovar Filiação' : 'Detalhes da Filiação'}</a>
                                        </div>
                                        {filiacao.statusFiliacao === 2 && (
                                            <>
                                                {exibirBotaoCarteirinhaDigital() && (
                                                    <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                                        <a href={`/carteirinhaDigital?fl=${filiacao.id}&fd=${idFederacao}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#ffc107' }}><i class="fa fa-newspaper-o" aria-hidden="true" style={{ color: "#fff" }}></i> Carteirinha Digital</a>
                                                    </div>
                                                )}
                                                {federacao?.carteirinha?.carteirinhaFisica !== 0 && (
                                                    <div class="package-style-1__btn vc_col-sm-5" style={{ marginBottom: '10px' }}>
                                                        <a href={`/carteirinha?fl=${filiacao.id}&fd=${idFederacao}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: 'rgb(238, 92, 7)' }}><i class="fa fa-newspaper-o" aria-hidden="true" style={{ color: "#fff" }}></i> Carteirinha Física</a>
                                                    </div>
                                                )}


                                            </>

                                        )}


                                        <div class="package-style-1__btn vc_col-lg-5" style={{ marginBottom: '10px' }}>
                                            <div class="btn_defoult btn_fill vc_col-lg-12" style={{ cursor: 'pointer' }} onClick={() => excluirFiliacao()} ><i class="fa fa-times" aria-hidden="true" style={{ color: "#fff" }}></i> Cancelar Filiação</div>
                                        </div>
                                    </div>

                                </>
                            )}

                            <div class={`wpb_column vc_column_container ${filiacao.id? 'vc_col-sm-8' : 'vc_col-sm-12'}`}>
                                <div class={`package-style-1__btn ${filiacao.id? 'vc_col-sm-5' : 'vc_col-sm-3'}`} style={{ marginBottom: '10px' }}>
                                    <a href={`/federacao/${idFederacao}`} class="btn_defoult btn_fill vc_col-sm-12" style={{ background: '#17a2b8' }}><i class="fa fa-arrow-left" aria-hidden="true" style={{ color: "#fff" }}></i> Voltar</a>
                                </div>
                            </div>

                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
